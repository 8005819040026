"use client";
import React, { createContext, useContext } from "react";
import HasNoTeamAccountUser from "~/components/LandingPages/HasNoTeamAccountUser";
import { api } from "~/server/trpc/react";
import { RouterOutputs } from "~/server/trpc/react";
import { toast } from "~/hooks/useToast";


type User = RouterOutputs["teamAccountUser"]["getTeamAccountUser"];

interface TeamAccountContextType {
  teamAccountUser: User | null | undefined;
  updateTeamAccountUser: (teamAccountUser: User) => void | null;
  updateTeamAccountUserAsync: (data: {
    contactEmail?: string;
    firstName?: string;
    lastName?: string;
  }) => Promise<void>;
  isLoading: boolean;
}

const TeamAccountUserContext = createContext<TeamAccountContextType | null>(
  null
);

export function useTeamAccountUser() {
  return useContext(TeamAccountUserContext) as TeamAccountContextType;
}

export function TeamAccountUserProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const utils = api.useUtils();

  // query for existing user.
  const {
    data: initialTeamAccountUser,
    isLoading: loadingQuery,
    isSuccess: successQuery,
    refetch,
  } = api.teamAccountUser.getTeamAccountUser.useQuery(void 0, {
    refetchOnWindowFocus: true,
  });

  const { mutate: updateTeamAccountUser } =
    api.teamAccountUser.updateTeamAccountUser.useMutation({
      onSuccess: () => {
        refetch().catch((err) =>
          console.error(err, "TeamAccountUserProvider.tsx")
        );
      },
    });

  const { mutateAsync: updateTeamAccountUserAsync } =
    api.teamAccountUser.updateTeamAccountUser.useMutation({
      onSuccess: () => {
        toast({
          title: "Profile updated",
          description: "Profile updated successfully",
        });
        refetch().catch((err) =>
          console.error(err, "TeamAccountUserProvider.tsx")
        );

        utils.teamAccount.getTeamAccountUsers.invalidate();

      },
      onError: (error) => {
        toast({
          title: "Error",
          description: "Failed to update profile",
          variant: "destructive",
        });
        console.error(error, "TeamAccountUserProvider.tsx");
      },
    });


  const handleUpdateTeamAccountUser = (teamAccountUser: User) => {
    if (!teamAccountUser) return;
    updateTeamAccountUser({
      ...teamAccountUser,
      contactEmail: teamAccountUser.contactEmail ?? undefined,
      firstName: teamAccountUser.firstName ?? undefined,
      lastName: teamAccountUser.lastName ?? undefined,
    });
  };

  const handleUpdateTeamAccountUserAsync = async (data: {
    contactEmail?: string;
    firstName?: string;
    lastName?: string;
  }) => {
    await updateTeamAccountUserAsync(data);
  };

  if (!initialTeamAccountUser && !loadingQuery && successQuery) {
    return <HasNoTeamAccountUser />;
  }
  if (loadingQuery) {
    return <></>;
  }
  return (
    <TeamAccountUserContext.Provider
      value={{
        teamAccountUser: initialTeamAccountUser,
        updateTeamAccountUser: handleUpdateTeamAccountUser,
        updateTeamAccountUserAsync: handleUpdateTeamAccountUserAsync,
        isLoading: loadingQuery,
      }}
    >
      {children}
    </TeamAccountUserContext.Provider>
  );
}
