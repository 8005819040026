import { useSession } from "next-auth/react";
import HeroPage from "~/components/layout/HeroPage";
import ConnectGoogle from "../layout/Sidebar/ConnectGoogle";
import ConnectGithub from "../layout/Sidebar/ConnectGithub";
import MobileScreen from "~/app/mobileScreen/page";
import { useIsMobile } from "~/hooks/use-mobile";

export default function HasNoTeamAccountUser() {
  const { status } = useSession();
  const isMobile = useIsMobile();

  if (status !== "unauthenticated") return <></>;

  return isMobile ? (
    <MobileScreen />
  ) : (
    <HeroPage>
      <div className="flex h-screen w-1/2  flex-col gap-4 content-center items-center justify-center">
        <div className=" text-sm text-gray-600">Step 1 of 2</div>
        <div className=" text-3xl font-bold">Let{"'"}s get you started!</div>
        <ConnectGoogle />
        <ConnectGithub />
        <div className=" w-1/2 text-center text-sm text-muted">
          By continuing, you acknowledge that you have read, understood and
          agree to our{" "}
          <a
            className="text-link hover:opacity-75"
            href="https://openq.dev/terms-of-service"
          >
            terms of service
          </a>{" "}
          and{" "}
          <a
            className="text-link hover:opacity-75"
            href="https://openq.dev/privacy_policy"
          >
            privacy policy
          </a>
          .
        </div>
      </div>
    </HeroPage>
  );
}
